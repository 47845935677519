import React from "react"

import Layout from "../../components/layout.tsx"

import FeedbackCard from "../../components/feedback_card.tsx"
import AboutUs from "../../components/about_us.tsx"
import copePage from "../../images/cope_hero.jpg"

const CopeProject = ({ data, location }) => {
  return (
    <Layout location={location} title="Citizen Cope NFT">
      <div className="cope-project-hero" />

      <div className="lg:w-1/2 mx-16 lg:ml-12 -mt-24 ">
        <div className="absolute top-80 right-36 hidden xl:block">
          <FeedbackCard location="projects/cope" />
        </div>
        <h1>NFT Tickets with Citizen Cope</h1>
        <p className="lead">
          Non-Fungible Tokens (NFTs) entered the consciousness of a major
          audience in early 2021. However, the focus was mainly around
          authenticity of digital art. This was a great first step in raising
          awareness to the mainstream audience about the existence of the
          digital art world and NFTs. However, it barely scratched the surface
          of NFT capabilities and was seen by many as expensive jpegs and mp4s.
          Team Zero had an opportunity to work alongside{" "}
          <a href="https://www.serto.id/" target="_blank" rel="noreferrer">
            Serto
          </a>{" "}
          and musician,{" "}
          <a href="https://citizencope.com/" target="_blank" rel="noreferrer">
            Citizen Cope
          </a>
          , to show how NFTs can impact the music industry.
        </p>
      </div>
      <div className="lg:w-1/2 mx-16 lg:mx-auto mt-16">
        <h3>Background</h3>
        <p>
          Team Zero is interested in transforming the experience of going to
          concerts and sporting events using new technologies. We have educated
          guesses about some of the benefits of using blockchain and Web3
          technology in these industries. In our vision statement, we pinpoint 5
          areas that we believe can be vastly improved with this technology
          (Digital Ticket Parity x10, Unlocks, Loyalty, New Collectible & Fan
          Markets, New Revenue Streams for Artists, Athletes, Organizations and
          Creators). To understand if our vision has validity, Team Zero builds
          lightweight experiments using web3 and other technologies and tests
          them with real customers.
        </p>
      </div>

      <div className="lg:w-1/2 mx-16 lg:mx-auto mt-16">
        <h3>Citizen Cope and Serto</h3>
        <p>
          In March of 2021,{" "}
          <a href="https://www.serto.id/" target="_blank" rel="noreferrer">
            Serto
          </a>{" "}
          (a fellow Mesh company focused on Decentralized Identity) and
          musician, Citizen Cope, approached Team Zero with an opportunity to
          build a Non-Fungible Token that would double as a ticket to Citizen
          Cope's show in New York City. Citizen Cope also had a unreleased
          version of "Something to Believe In" that he wanted to include with
          the sale. This matched perfectly with Team Zero's vision of NFT
          unlocks in digital ticket space. We see future tickets not only
          providing more secure ownership and entry into a space, but having
          other abilities that outlast the event and keep it memorable.
        </p>
        <p className="mt-4">
          In collaboration with Serto and Citizen Cope, Team Zero built a{" "}
          <a
            href="https://nft.citizencope.com/"
            target="_blank"
            rel="noreferrer"
          >
            microsite to host the special NFT promotional page
          </a>
          . Serto created the{" "}
          <a
            href="https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/11752296639496409238383117470960268739486257866539113581700845555472905797633"
            target="_blank"
            rel="noreferrer"
          >
            NFT for Citizen Cope on OpenSea.io
          </a>{" "}
          that would was to be auctioned off.
        </p>
        <img className="w-full" src={copePage} alt="Citizen Cope NFT website" />
        <p className="mt-4">
          The site had 4 states. There was the pre-auction state, that gave
          basic information about the auction, what to expect and had a
          countdown to the auction. The Post-auction had 3 states. The first, if
          you didn't have a Web3 wallet, those users would get the state that
          prompts them to get one (and how to get one as well as how to learn
          about what one is and its function). The page still had information
          about the auction and concert. The second, If you had a wallet, the
          site prompted the user to connect to their Web3 Wallet. This allowed
          the user to connect to{" "}
          <a href="https://metamask.io/" target="_blank" rel="noreferrer">
            MetaMask
          </a>
          ,{" "}
          <a
            href="https://docs.walletconnect.org/"
            target="_blank"
            rel="noreferrer"
          >
            WalletConnect
          </a>
          ,{" "}
          <a href="https://www.portis.io/" target="_blank" rel="noreferrer">
            Portis
          </a>
          , and{" "}
          <a href="https://fortmatic.com/" target="_blank" rel="noreferrer">
            Formatic
          </a>
          . If the user didn't own the NFT, they were notified that their wallet
          is connected, but they are not the owner of the NFT. The page still
          had information about the auction and the concert. The third state was
          for if the user connected to their wallet and owned the NFT, they got
          a unique experience tailored to them. It included 4 front-row tickets
          to Citizen Cope's concert in NYC on May 15. It also included his never
          before played version of "Something to Believe In" that they could
          play from the site.
        </p>
      </div>
      <div className="lg:w-1/2 mx-16 lg:mx-auto my-16">
        <h3>Results</h3>
        <p>
          On April 30, 2021, Citizen Cope's NFT ticket was auctioned off and
          sold for 7.7777 ETH (c. $23,000+) on OpenSea.io. We added a contact
          area to the microsite to field any questions that the buyer might
          have. This became a crucial tool as the buyer had contacted us on
          multiple occasions and provided us with a wealth of knowledge. We had
          some glitches on mobile and there were expectations that the buyer had
          that we didn't anticipate. The buyer also asked about certain
          features. We also noticed some odd conflicts with the OpenSea api.
        </p>
        <p className="mt-4">
          For the first of its kind, this was a definite success and has
          provided a lot of feedback on where improvements could be made and
          fans and artists actually care about. Team Zero is looking to pair up
          with more artists in the future to push the boundaries further.
        </p>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <div className="m-auto order-last mt-6 lg:order-first lg:mr-10 lg:ml-auto">
          <FeedbackCard location="projects/cope" />
        </div>
        <div className="mt-8 ml-1 mr-10">
          <AboutUs />
        </div>
      </div>
    </Layout>
  )
}

export default CopeProject
